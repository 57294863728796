// Components
import CategoryForm from '@/components/elements/categories/CategoryForm'
import SectionInformationBox from '@/components/ui/SectionInformationBox'
import VuetifyToolBar from '@/components/ui/VuetifyToolBar'
// Mixins
import onboardingMixin from '@/mixins/onboardingMixin'
import uiMixin from '@/mixins/uiMixin'
import intercomMixin from '@/mixins/intercomMixin'
// Vuex
import { mapMutations } from 'vuex'

export default {
  name: 'OnboardingMenu',
  components: { SectionInformationBox, VuetifyToolBar },
  mixins: [onboardingMixin, uiMixin, intercomMixin],
  mounted() {
    this.getEveryNeededData()
  },
  destroyed() {
    // Paramos escucha
    this.$root.$off('completed-category-form')
  },
  methods: {
    ...mapMutations('authentication', ['setOnboadingSection']),
    /**
     * Usuario pulsa en el botón de acción
     */
    handleClickButton() {
      this.showFormDialog()
    },
    /**
     * Obtenemos todo lo necesario para inicializar el componente
     */
    getEveryNeededData() {
      // Capturamos evento
      this.$root.$on('completed-category-form', this.captureCategoryFormCompleted)
    },
    /**
     * Mostramos el diálogo del formulario
     */
    showFormDialog() {
      // Si no tiene numero o place le pedimos que lo complete
      this.modifyAppDialog({
        title: 'Crear Carta / Menú',
        contentComponent: CategoryForm,
        hideActionButtons: true,
        visible: true
      })
    },
    /**
     * Tratamos el evento de completitud del formulario de menú
     *
     * @param {object} data - datos del evento
     */
    async captureCategoryFormCompleted(data) {
      // Modificamos variable de Intercom
      this.intercomUpdate({
        bakarta_onboarding_menu: true
      })

      // Cerramos modal
      this.modifyAppDialog({
        visible: false
      })
      // Seteamos parámetros en Store del onboarding
      this.setOnboadingSection({
        section: 'category',
        params: data
      })
      // Marcamos sección como completada
      await this.setSectionCompleted('menu')
    }
  }
}
